import { useState } from 'react'
import './App.scss';
import { Alert, Image, FloatingLabel, Form, Button } from "react-bootstrap"
import ExamUploadBox from './ExamUploadBox';

export default function UploadPage() {

    const [stage, setStage] = useState(0)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    //const [stage, setStage] = useState(7)
    return (
        <div>
            <header className="App-header">
                <h3>Aiempien ylioppilaskokeiden mallivastaukset. Ilmaiseksi.</h3>
                <h4>Jaa omat vastauksesi muille turvallisesti ja anonyymisti.</h4>
            </header>
            <br></br>
            <div className="UploadPage">
                <div className="UploadBox">
                    {uploadBox(stage, setStage, submitDisabled, setSubmitDisabled)}
                </div>
            </div>
            
        </div>
    )
}

let errorMessage = ""
let serverData = {}

function uploadBox(stage, setStage, submitDisabled, setSubmitDisabled) {
    function formSubmit(argument) {
        console.log("formSubmit")
        var url = "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/newverify";
        //var url = "http://localhost:8080/newverify";
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.onload = function() { // request successful
        // we can use server response to our request now

            if (JSON.parse(request.responseText) !== undefined && JSON.parse(request.responseText).success == true) {
                console.log("a")
                serverData =JSON.parse(request.responseText);
                console.log("b")
                setStage(7)
            } else {
                errorMessage = (JSON.parse(request.responseText) !== undefined ? JSON.parse(request.responseText) : request.responseText)
                setStage(-2)
            }
            
        };
      
        request.onerror = function() {
            errorMessage = request.responseText
            setStage(-2)
        };
        let formData = new FormData()
        formData.append("url", argument)
        request.send(formData);
        setStage(6)
      }

    console.log(stage)
    if (stage === -2) {
        return (
            <>
                <Alert variant="danger">
                    <p style={{whiteSpace: "pre-line"}}>Palvelimen virhe. {(typeof(errorMessage) !== "object" ? errorMessage : errorMessage.error)}</p>
                </Alert>
                <button onClick={() => window.location.reload(true)}>Takaisin</button>
            </>

        )
    }

    if (stage === -1) {
        return (
            <>
                <p>Vastausten jakaminen vaatii tietokoneen sekä pääsyn Oma Opintopolku-palveluun. Tällä hetkellä vain Laudaturin arvosanan saaneiden suoritusten vastauksia voi jakaa.</p>
                <button onClick={(() => setStage(0))}>Aloita</button>
            </>
        )
    }
    if (stage === 0) {
        return (
            <>
                <br></br>
                <h4>Vaihe 1: Suorituksen todistaminen</h4>
                <p>Vaadimme suorituksen todistamisen kaikilta vastauksen jättäviltä. Emme säilytä henkilötietojasi tai jaa niitä muille.</p>
                <p>Todistaminen tapahtuu Oma Opintopolku-palvelusta saatavilla olevan YO-todistuslinkin kautta. Avaa Oma Opintopolku <a href="https://opintopolku.fi/oma-opintopolku/" target="_blank"> klikkaamalla tästä</a> (avautuu uuteen välilehteen). </p>
                <p>Paina 'Siirry opintosuorituksiin (vaatii tunnistautumisen)' ja tunnistaudu.</p>
                <hr></hr>
                <Image src="https://i.imgur.com/vBc6xyj.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(1))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 1) {
        return (
            <>
                <h4>Vaihe 2: Suoritusten jakolinkin luominen</h4>
                <p>Tunnistauduttuasi sinulla pitäisi nyt olla alla olevan tapainen näkymä. Paina nappia 'Jaa suoritustietoja'.</p>
                <hr></hr>
                <Image src="https://i.imgur.com/lzOBAjq.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(2))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 2) {
        return (
            <>
                <h4>Vaihe 3: Suoritusten jakolinkin luominen</h4>
                <p>Valitse vasemmasta valikosta 'Ylioppilastutkinto'. Muita vaihtoehtoja ei tarvitse valita. Paina sitten 'Jaa valitsemasi opinnot'</p>
                <hr></hr>
                <Image src="https://i.imgur.com/ykCvhXx.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(3))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 3) {
        return (
            <>
                <h4>Vaihe 4: Jakolinkin lähetys</h4>
                <p>Paina kopioi linkki. Liitä kopioitu linkki alla olevaan kenttään. Voit liittää leikepöydältäsi käyttäen kirjainyhdistelmää CTRL + V kun vastauskenttä on valittuna.</p>
                <hr></hr>
                <br></br>
                <Image src="https://i.imgur.com/lvqUZK0.png" fluid className="Image" alt=""></Image>
                <br></br>
                <FloatingLabel
                    controlId="urlUpload"
                    label="Liitä opintopolku.fi -linkki tähän"
                    className="mb-3"
                >
                    <Form.Control onChange={(event) => {
                        let regex = /https:\/\/opintopolku\.fi\/koski\/opinnot\/([A-Za-z0-9]+)/i
                        setSubmitDisabled(!event.target.value.match(regex))
                        console.log(event.target.value.match(regex))
                    }}/>
                </FloatingLabel>
                <Button variant="secondary" disabled={submitDisabled} onClick={ () => {
                    if (document.getElementById("urlUpload").value !== "") formSubmit(document.getElementById("urlUpload").value)
                }}>Lähetä</Button>
                
            </>
        )
    }
    if (stage === 6) {
        return (
            <>
                <h4>Ladataan...</h4>
                <p>YO-todistustasi tarkistetaan. Tämän ei pitäisi kestää paria sekuntia kauempaa.</p>
                <br></br>
            </>
        )
    }
    if (stage === 7) {
        return (
            <>
                <h4>Vaihe 7: Vastausten lisääminen</h4>
                <p>YO-todistuksesi tarkastus onnistui. Voit nyt ladata vastauksesi palveluun. Onnittelut menestyksestä kirjoituksissa!</p>
                <p>Voit ladata vastauksia alla oleviin aineisiin. Vastausten lataaminen tapahtuu painamalla Opintopolun 'Näytä koesuoritus' painiketta. Kun koe on auki selaimessa, paina näppäinyhdistelmää CTRL + S ja tallenna html-tiedosto laitteellesi. Lähetä kyseinen html-tiedosto tämän sivun latauskenttiin alla.</p>
                <p>Jos et halua antaa jonkun kokeen vastauksia, voit painaa vasenta nappia ja olla näin lataamatta kyseistä koetta.</p>
                <p style={{color: "red"}}>Älä muokkaa tiedostoja millään tavalla. Tarkastamme kaikkien vastausten alkuperäisyyden. Muokatun vastauksen lähetys johtaa automaattiseen porttikieltoon.</p>
                <hr></hr>
                <br></br>
                <ExamUploadBox serverData={serverData}/>
                <Image src="https://i.imgur.com/ExiBK1L.png" fluid className="Image" alt=""></Image>
                <br></br>
            </>
        )
    }
}