import { useState } from 'react'
import { Form, Button, Alert } from "react-bootstrap"


export default function ExamUploadBox(props) {
    let serverData = JSON.parse(JSON.stringify(props.serverData))
    let someUploadRetryable = false
    const [getDisabledUploadBoxes, setDisabledUploadBoxes] = useState([])
    const [uploadData, setUploadData] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [areSomeUploadsRetryable, setSomeUploadsRetryable] = useState(false)

    function performUpload(event) {
        var url = "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/examUpload";
        //var url = "http://localhost:8080/examUpload";
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.onload = function() { // request successful
        // we can use server response to our request now
    
            if (JSON.parse(request.responseText) !== undefined && JSON.parse(request.responseText).success == true) {
                setUploadData(JSON.parse(request.responseText))
                for (let entry of Object.keys(JSON.parse(request.responseText).result)) {
                    if (JSON.parse(request.responseText).result[entry].allowretry) {
                        someUploadRetryable = true
                        setSomeUploadsRetryable(true)
                        console.log("A: " + someUploadRetryable)
                    } else document.getElementById("upload-"+entry).value = null
                }
            } else {
                setErrorMessage((JSON.parse(request.responseText) !== undefined ? JSON.parse(request.responseText).error : request.responseText))
            }
            
        };
        
        request.onerror = function() {
            if (request.responseText !== "") {
                setErrorMessage(request.responseText)
            } else {
                setErrorMessage("Palvelimeen ei saatu yhteyttä.")
            }
            
        };

        let formData = new FormData()
        for (let exam of serverData.exams) {
            if (!exam.forbidUpload) {
                if (!getDisabledUploadBoxes.includes("upload-"+exam.subject)) {
                    formData.append("file-"+exam.subject, document.getElementById("upload-"+exam.subject).files[0])
                }
            }
        }
        formData.append("old",JSON.stringify(serverData))
        console.log(formData)
        request.send(formData); // create FormData from form that triggered event
        event.preventDefault(); 
    }
    return (
        <Form id="uploadForm" onSubmit={performUpload}>
            {serverData.exams.map((idx) => (
                <>
                    {(!(idx.forbidUpload && (uploadData !== null)) && (
                        <div id={"onion-1-"+idx.subject}>
                        {(idx.forbidUpload && (
                            <>
                                <h5 className="Grayed">{idx.season + " " + idx.year + ": " + idx.subject + " (" + idx.grade + ")"}</h5>
                                {<p style={{color: "red", marginTop: "0"}}>Et voi jakaa tämän kokeen vastauksia. {idx.forbidReason}</p>}
                            </>
                        ))}
                        {(!idx.forbidUpload && <h5 style={{marginBottom: "1em"}}>{idx.season + " " + idx.year + ": " + idx.subject + " (" + idx.grade + ")"}</h5>)}
                        
                        
                            <div id={"onion-2-"+idx.subject} className="ExamUploadBox">
                                <Form.Check type="switch" defaultChecked={!idx.forbidUpload} hidden={uploadData !== null && uploadData.result.hasOwnProperty(idx.subject) && uploadData.result[idx.subject].allowretry !== true} disabled={idx.forbidUpload} onChange={(a) => {
                                    if(!a.currentTarget.checked) {
                                        setDisabledUploadBoxes(["upload-"+idx.subject, ...getDisabledUploadBoxes])
                                        document.getElementById("upload-"+idx.subject).value = null
                                    } else {
                                        let arr = [...getDisabledUploadBoxes]
                                        
                                        arr.splice(arr.indexOf("upload-"+idx.subject), 1)
                                        setDisabledUploadBoxes(arr)
                                    }
                                    
                                }} id={"switch-"+idx.subject}/>
                                <Form.Control id={"upload-"+idx.subject} hidden={uploadData !== null && (uploadData.result.hasOwnProperty(idx.subject) && uploadData.result[idx.subject].allowretry === false || idx.forbidUpload)} required={!(getDisabledUploadBoxes.includes("upload-"+idx.subject) || idx.forbidUpload)} disabled={getDisabledUploadBoxes.includes("upload-"+idx.subject) || idx.forbidUpload}type="file"/>
                                </div>
                        {(uploadData !== null && uploadData.result.hasOwnProperty(idx.subject) && (
                            <>
                                {(uploadData.result[idx.subject].allowretry === false && (
                                    <>
                                        {Object.keys(uploadData.result[idx.subject]).map((index) => {
                                            if (index !== "issue" && index !== "allowretry" && (uploadData.result[idx.subject][index]).hasOwnProperty("id")) {
                                                return <p style={{color: "green"}}>✓ Kysymys {index} - Vastauksesi jaettiin tunnisteella <b>{uploadData.result[idx.subject][index].id}</b> ({uploadData.result[idx.subject][index].points} pistettä)</p>
                                            } else if (index !== "issue" && index !== "allowretry") {
                                                return <p style={{color: "red"}}>𐄂 Kysymys {index} - Vastaustasi ei jaettu. {uploadData.result[idx.subject][index].issue} ({uploadData.result[idx.subject][index].points} pistettä)</p>
                                            }
                                        })}
                                    </>
                                ))}
                                {(uploadData.result[idx.subject].hasOwnProperty("issue") && (
                                    <p style={{color: "red"}}>𐄂 {uploadData.result[idx.subject].issue}</p>
                                ))}
                                
                            </>
                        ))}
                        
                        <hr></hr>
                        <br></br>
                    </div>
                    ))}
                </>
            ))}
            {((uploadData === null || areSomeUploadsRetryable === true) && (
                <>
                    <Form.Check required id="termsandconditions" label="Olen lukenut palvelun käyttöehdot ja suostun niihin."></Form.Check>
                    <Form.Check required id="affirmation" label="Vakuutan etteivät vastaukseni sisällä kenenkään yksityistietoja."></Form.Check>
                    <br></br>
                    <Button style={{minWidth: "40%", marginLeft: "-0.5em"}} id={"submit"} type="submit">Lähetä</Button>
                    {errorMessage !== "" && (
                        <Alert variant="danger">
                            {errorMessage}
                            <br></br>
                            <p>Jos virhe liittyy vastausten jakamiseen voi olla, että joidenkin vastausten lataaminen onnistui. Voit kuitenkin huoletta ladata loputkin kokeesi. Palvelu pysäyttää automaattisesti saman kokeen vastausten lataamisen uudestaan.</p>
                        </Alert>
                    )}
                    <br></br>
                    <hr></hr>
                </>
            ))}
            {console.log("B: " + areSomeUploadsRetryable)}
            {(uploadData !== null && areSomeUploadsRetryable === false && (
                <>
                    <p>Vastauksesi on nyt lähetetty. Voit palata takaisin etusivulle.</p>
                    <Button style={{minWidth: "70%", marginLeft: "-0.5em"}} id="finish" onClick={() => window.location.reload(true)} type="submit">Takaisin etusivulle</Button>  
                </>
            ))}
            
        </Form>
    )
}